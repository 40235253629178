body,
html,
section {
  /* animation-name: change-background;
  animation-duration: 120s;
  animation-iteration-count: infinite; 
   background-color: #095256;  */
  background-color: #4056f4;
  height: 100%;
  margin: 0;
}

.link {
  text-decoration: underline;
  color: inherit;
}

.link-text {
  font-size: 15px;
  font-style: italic;
  opacity: 0.6;
}

.taskbar {
  background-color: #4d62fc;
  height: 100vh;

  /* flex: 0.4; */
  width: 30%;
  position: fixed;
  overflow-y: scroll;
  box-sizing: content-box;
}

.div-left {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.left-half {
  background-color: #4d62fc;
  flex: 0.3;
  padding: 1rem;
  text-align: center;
  /* align-self: stretch; */
  height: 100vmax;
}

.right-half {
  background-color: #4056f4;
  flex: 1;
  padding: 1rem;
  text-align: center;
  flex-wrap: wrap;
}

.container {
  /* max-width: 55%;
  margin: 0 auto;
  padding: 50px; */

  display: flex;
  margin: 0 auto;
  flex-direction: row;
}

.popup-header {
  color: #000;
  text-align: center;
}

.task-component {
  background-color: #4d62fc;

  float: left;
  text-align: center;
  width: 25%;
  flex: 0.5;

  /* min-height: 1800px; */
  min-height: 1400px;
}

.sound-component {
  float: right;
  text-align: center;
  width: 75%;
  /* flex: 0.7; */
}

/* background of todo list */
.todo-list {
  background: none;
  border-radius: 4px;
  /* max-width: 400px; */
  padding: 5px;
}

.todo {
  align-items: left;
  background: none;
  /* border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); */
  font-family: "Karla", sans-serif;
  display: flex;
  font-size: 20px;
  line-height: calc(1.3em + (1.5 - 1.3) * ((100vw - 300px) / (1600 - 300)));
  font-style: italic;
  color: #fff;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 3px 15px;
}

@keyframes change-background {
  /* 0% {
    background-color: #48639c;
  }
  10% {
    background-color: #4C4C9D;
  }
  20% {
    background-color: #5F3E8B;
  }
  30% {
    background-color: #712F79;
  }
  40% {
    background-color: #976391
  }
  50% {
    background-color: #A0719B
  }
  60% {
    background-color: #A0719B
  }
  70% {
    background-color: #976391
  }
  80% {
    background-color: #712F79;
  }
  90% {
    background-color: #5F3E8B;
  }
  100% {
    background-color: #48639c;
  } */

  0% {
    background-color: #095256;
  }
  10% {
    background-color: #087f8c;
  }
  20% {
    background-color: #5aaa95;
  }
  30% {
    background-color: #86a873;
  }
  40% {
    background-color: #86a873;
  }
  50% {
    background-color: #bb9f06;
  }
  60% {
    background-color: #bb9f06;
  }
  70% {
    background-color: #86a873;
  }
  80% {
    background-color: #5aaa95;
  }
  90% {
    background-color: #087f8c;
  }
  100% {
    background-color: #095256;
  }
}

.popup {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  border-radius: 30px;
  background-color: #ffff;
  /* rgba(0,0,0, 0.5);  */
}

.popup\_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tws-bg {
  background-color: #f24330;
}

html,
body,
#app {
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: "Karla", sans-serif;
  color: white;
  font-style: "italic";
  font-weight: 400;
  font-size: calc(28px + (30 - 28) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.2em + (1.3 - 1.2) * ((100vw - 300px) / (1600 - 300)));
}

.tws-header {
  padding-bottom: 5%;
  padding-top: 2%;
  font-family: "Karla", sans-serif;
  font-weight: 100px;
  font-size: calc(25px + (35 - 25) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.2em + (1.3 - 1.2) * ((100vw - 300px) / (1600 - 300)));
  color: #ffff;
  text-align: center;
}

.tws-task-header {
  padding-left: 7%;
  padding-right: 7%;
  color: #fff;
  text-align: left;
}

.field {
  width: 100%;
  height: 56px;
  border-radius: 25px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: #512da8;
}

.field.locked {
  pointer-events: none;
}

.field input {
  width: 90%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  /* font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; */
  font-style: italic;
  font-family: "Karla", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #fff;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.field input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;

  /* font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; */
  font-family: "Karla", sans-serif;
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}

.field input + label.error {
  color: #ec392f;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  /* font-family: "Gotham SSm A", "Gotham SSm B", sans-serif; */
  font-family: "Karla", sans-serif;
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.dark {
  color: #dadada;
  background: #1c2022;
  min-height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-clear {
  border: none;
  background: transparent;
  outline: none;
}

.left {
  float: left;
  text-align: left;
  /* width: 45%; */
  width: 10%;
}

.center {
  float: left;
  text-align: center;
  width: 10%;
}

.right {
  float: left;
  text-align: right;
  /* width: 45%; */
  width: 90%;
}

.nav-link {
  font-size: 25px;
  /* font-weight: bold; */
  text-decoration: none;
  color: inherit;
}

.nav li {
  margin-left: 15px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.header-lg {
  font-size: 35px;
  font-weight: 300;
  margin: 20px;
}

.header {
  position: absolute;
  height: 60px;
  top: 0;
  left: 0;
  right: 0;
}

.center-text {
  text-align: center;
}

.card {
  margin: 10px 0;
  width: 200px;
  padding: 5px;
}

.card img {
  margin-bottom: 8px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.topnav {
  float: right;
  text-align: center;
  text-decoration: none;
}

.topnav-right {
  float: right;
}

.row-nav {
  float: right;
  display: flex;
  text-align: right;
  align-content: right;
  flex-direction: row;
  /* padding-left: 55%; */
  /* flex-direction: row; */
}

.btn {
  /* padding-left: 10%; */
  text-decoration: uppercase;
  /* letter-spacing: 0.25em; */
  border-radius: 3px;
  border: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  /* max-width: -200px;  */
  outline: "none";
}

.light-btn {
  color: #000;
  background: none;
}

.todo-btn {
  color: #fff;
}

.light-btn-pause:hover {
  opacity: 0.6;
  background: none;
}

.light-btn-pause {
  opacity: 0.3;
  background: none;
}

.light-btn:disabled {
  background: #292929;
  color: #4a4a4a;
}
